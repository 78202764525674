import { graphql } from 'gatsby'
import React from 'react'

import animation from '../../assets/blog/18/1.gif'
import webImg from '../../assets/blog/18/2.png'
import mobileImg from '../../assets/blog/18/3.png'
import heroImg from '../../assets/blog/18/hero.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Kdo si zvládne zorganizovat čas, vyhrává" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Kdo si zvládne zorganizovat čas, vyhrává" />
        <ContentSubPage>
          <div className="w-3/4 mx-auto my-12">
            <img
              src={heroImg}
              alt="Jak přežít stavbu domu"
              className="object-cover w-full max-h-[600px] rounded-[20px]"
            />
          </div>
          <p>
            Naši dávní předkové jistě neměli lehký život. Dennodenně čelili
            bezprostřednímu nebezpečí a co si neulovili, to neměli. Navzdory
            všemu pokroku na tom však dnes nejsme s koncentrací stresových
            hormonů v těle o mnoho lépe. Namísto tygra šavlozubého na nás útočí
            například společenský tlak na výkon, nutnost být ve střehu na příliš
            mnoha frontách najednou a mimo jiné taky nedostatek aktivního pohybu
            a kvalitního stravování.
          </p>
          <p>
            Dá se říci, že všichni bychom dnes potřebovali osobního asistenta a
            kouče v jednom. Opravdu všichni, včetně osobních asistentek, které
            navíc v pracovním životě řeší úkoly druhých.
          </p>
          <p>
            Na vlastní paměť už se spoléhat nedá, ta se mimo jiné vlivem
            chronického stresu zhoršuje. Diáře, papírové seznamy a poznámky v
            počítači či mobilu selhávají především proto, že je obvykle
            kombinujeme, a pak zbytečně ztrácíme čas hledáním toho místa, kam
            jsme si určitý úkol napsali či uložili. Důvodem, proč se tohoto
            nedokonalého systému mnozí držíme je, že není snadné najít jedno
            řešení pro různé typy poznámek, upozornění a soupisů. Flagis to ale
            umí. Navíc poskytuje mailový nástroj pro posílání úkolů a
            komunikaci. Zcela flexibilně se přizpůsobí specifickým potřebám a
            přehledně rozdělí položky přesně tak, jak je pro nás nejvhodnější.
            Zorganizuje pracovní úkoly, zpřehlední a pohlídá volnočasové
            aktivity, pracovní i osobní schůzky, účty, návštěvy lékaře a další.
            Jde o neocenitelný nástroj pro podnikatele, OSVČ, ale i studenty či
            rodiče na rodičovské dovolené. Osvědčuje se při stavbě či
            rekonstrukci bydlení a ulehčí také plánování dovolené.
          </p>
          <p>
            Je více cest, jak dostat stres pod kontrolu. Pokud se ale nemůžeme
            odstěhovat do lesů a obrátit si život naruby, pořád bude tím
            nejdůležitějším faktorem umění zvládnout organizaci svého času. Proč
            nevyužít těch nejefektivnějších nástrojů, mezi které se Flagis řadí.
          </p>

          <div className="my-5">
            <a href={animation} target="_blank" rel="noreferrer">
              <img
                src={animation}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={webImg} target="_blank" rel="noreferrer">
              <img
                src={webImg}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={mobileImg} target="_blank" rel="noreferrer">
              <img
                src={mobileImg}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
